.ticket{
    display: flex;
    flex-direction: row;
    border-radius: .5rem;
    background-color: white;
    gap: 1rem;
    padding: .3rem;
    cursor: pointer;
}
.containerDetail{
    position: absolute;
    display: grid;
    place-items: center;
    width: 30rem;
    height: 20rem;
    background-color: rgba(0, 0, 0, 0.178);
    /* margin-top: -5rem; */
    border-radius: 1rem;

}
.containerDetail>span{
    cursor: pointer;
}
.detail{
    display: flex;
    width: 23rem;
    position: absolute;
    flex-direction: column;
    background-color: rgb(226, 226, 226);
    border: 2px solid rgb(226, 226, 226);
    max-height: 12rem;
    border-radius: .5rem;
    padding: .5rem;
    gap: .5rem;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 1000;
}
.detail::-webkit-scrollbar {
    -webkit-appearance: none;
}

.detail::-webkit-scrollbar:vertical {
    width:10px;
}

.detail::-webkit-scrollbar-button:increment,.detail::-webkit-scrollbar-button {
    display: none;
} 

.detail::-webkit-scrollbar:horizontal {
    height: 10px;
}

.detail::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.detail::-webkit-scrollbar-track {
    border-radius: 10px;  
}
.info{
    display: grid;
    grid-template-columns: auto 1.5fr .5fr .5fr 1fr;
    border: 1px solid black;
    border-radius: .5rem;
    align-items: center;
    gap: 1rem;
    background-color: white;
}

.info img{
    width: 4rem;
    height: 4rem;
}