.navbar {
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    background-color: #272727;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 10000;
}

.navbar h1 {
    color: white;
    font-family: "Rubik", sans-serif;
    margin-left: -20rem;
}

.logo {
    width: 4rem;
    height: 4rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: fit-content;
    gap: 8rem;
    width: 27%;
}

.buttons button {
    border: none;
    background: none;
    font-size: 15px;
    color: #f5f5f5;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    transition: 0.3s;
    cursor: pointer;
}

.buttons button:hover {
    transform: scale(1.1);
}

.currentPag {
    text-decoration: dashed !important;
    color: rgba(252, 172, 3, 1) !important;
    font-size: 20px;
    transition: 0.3s;
}

/* Te copie este css, Gracias pedro */
.userContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    height: 30%;
    gap: 2em;

    color: white;
    padding: 0 30px;
}
.userContainer > img {
    border-radius: 360px;
    width: 40px;
    height: 40px;
}
