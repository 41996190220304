.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    gap: 3rem;
    align-items: center;
    padding: 5rem 1rem 3rem 1rem;
    background-color: #272727;
    max-width: 10rem;
    color: white;
    position: fixed;
    top: 3rem;
    z-index: 9000;
}

.containerButtons {
    display: flex;
    flex-direction: column;
    font-family: "Rubik", sans-serif;
    cursor: pointer;

}

.principalButton {
    display: flex;
    flex-direction: row;
    border: 0.5px solid gray;
    width: 8rem;
    justify-content: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    z-index: 1000;
    cursor: pointer;
}
.principalButton img {
    width: 1rem;
    height: 1rem;
    margin-right: 0;
    cursor: pointer;
}
.dropDowns {
    margin-left: 9rem;
    display: flex;
    flex-direction: column;
    position: absolute;
    height: fit-content;
    gap: .2rem;
    background-color: #272727;
    width: fit-content;
}

.dropDownsButtons{
    color: white;
    text-decoration: none;
    width: 9rem;
    padding: .5rem;
}

.orders{
    color: white;
    text-decoration: none;
}