.card{
  display: flex;
  border-bottom: 1px solid gray;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  font-family: "Rubik", sans-serif;
}

.card img{
  width: 5rem;
  height: 5rem;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 20px ;
  margin-top: 15px;
  margin-right: 15px;
}

.quantity{
  border: 1px solid #e7e7e7;
  padding: 1rem .3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  border-radius: 50%;
  font-size: 12px;
}

.left{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}