.page{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 7rem;
    font-family: "Rubik", sans-serif;
    font-size: larger;
}

.container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 11rem;
    /* border: 1px solid red; */
    margin-right: -11.5rem;
}
.graphic{
    /* border: 1px solid green; */
    position: relative;
    width: 35rem;
    height: fit-content;
    display: grid;
    place-items: center;
}

.description{
    display: grid;
    grid-template-columns: repeat(3, auto);
    width: 29rem;
    /* gap: -2rem; */
    padding-left: 2.5rem;
    /* border: 1px solid blue; */
}
.total{
    margin-right: -4rem;
}
.sales{
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: .5rem;
    gap: 1rem;
    margin-right: -10rem;
    padding: 1rem;
    position: relative;
    margin-top: 1rem;
}
.sales img{
    width: 40rem;
}