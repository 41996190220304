.title {
  background: #8f1414;
  min-width: 10rem;
  height: 4rem;
  border-bottom-left-radius: 5em;
  border-bottom-right-radius: 5em;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
}

.title h1 {
  width: 24rem;
  margin-bottom: 4rem;
  font-family: "Rubik", sans-serif;
  font-weight: 900;
  color: #f5f5f5;
  text-align: center;
  font-size: 1.5rem;

  margin: 0;
  line-height: 4rem;
}
