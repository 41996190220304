@import url("https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap");

.superDiv {
  width: 100vw;
  height: 105vh;
  margin-top: -1.5rem;
  margin-bottom: -3rem;
  display: grid;
  place-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("./image/background.jpeg");
}

.createLogin {
  width: 32rem;
  height: 32rem;
  background: rgba(16, 16, 16, 0.8);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  
}

.prev {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.prev > p {
  color: #f5f5f5;
  font-weight: 600;
  font-size: 24px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 5px;
}

.image {
  width: 100.73px;
  height: 100.73px;
  left: 214px;
  top: 71.29px;
  transform: rotate(-14.54deg);
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -3rem;
}

.col {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.col > label {
  color: #f5f5f5;
  font-size: 15px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.col .errorText {
  max-width: 329px;
  color: rgb(247, 116, 116);
  font-size: 13px;
}

.col > input {
  border: 1px solid #f5f5f5;
  border-radius: 5px;
  background-color: transparent;
  width: 329px;
  height: 37px;
  margin-top: 5px;
  color: #f5f5f5;
}

.errorInput {
  border: 2px rgb(247, 116, 116) solid !important;
}

.col > a {
  color: #f5f5f5;
  font-size: 13px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.logins > button {
  width: 335px;
  height: 40px;

  background: #8f1414;
  border-radius: 5px;
  border: 0px;
  font-weight: 300;
  font-size: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #f5f5f5;
  margin-top: 5px;
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 230px;
}

.checkbox > label {
  color: #f5f5f5;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.question {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 10px;
}

.question > p {
  color: #b7b5b5;
  margin: 0%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.question > a {
  text-decoration: none;
  color: #f5f5f5;
  margin-left: 5px;
}
