.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50rem;
  overflow: hidden;
}


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 70%;
  margin-left: 10.8rem;
  margin-top: 3.8rem;
}

.formBox {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 600px;
  /* height: 600px; */
  height: auto;
  border-radius: 5px;
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.27);
}

.errorInput {
  border: 2px rgb(255, 140, 140) solid !important;
}

.formRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: aqua; */
  gap: 1.5em;
  width: 65%;
  height: auto;
}


.select {
  width: 355px;
  height: 34px;
  border-radius: 5px;
  border: none;
  color: rgba(143, 143, 143, 1);
  background-color: rgba(183, 177, 177, 0.5);
  font-family: inherit;
  font-size: 17;
  padding: 2px 10px;
}


.options {
  font-family: inherit;
  font-weight: 500;
  color: rgba(143, 143, 143, 1);
  font-size: 17;
}

.inputs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  width: auto;
  height: auto;
}

.inputs > input {
  width: 334px;
  height: 30px;
  border-radius: 5px;
  border: none;
  color: rgba(143, 143, 143, 1);
  background-color: rgba(183, 177, 177, 0.5);
  font-family: inherit;
  font-size: 17;
  padding: 2px 10px;
}


.inputs > input::placeholder {
  font-family: inherit;
  font-weight: 500;
  color: rgba(143, 143, 143, 1);
  font-size: 17;
}

.inputs > label {
  font-family: inherit;
  font-weight: 500;
  font-size: 13px;
}

.inputs > button {
  width: 140px;
  height: auto;

  color: #f5f5f5;
  font-family: "Rubik";
  background-color: rgba(252, 172, 3, 1);
  /* font-weight: 900; */
  font-size: 20px;
  padding: 4px 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  border: none;
}

/* ------------------------- */

.inputData {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  width: auto;
  height: auto;
}

.inputData > input {
  width: 150px;
  height: 30px;
  border-radius: 5px;
  border: none;
  color: rgba(143, 143, 143, 1);
  background-color: rgba(183, 177, 177, 0.5);
  font-family: inherit;
  font-size: 17;
  padding: 2px 10px;
}

.inputData > input::placeholder {
  font-family: inherit;
  font-weight: 500;
  color: rgba(143, 143, 143, 1);
  font-size: 17;
}

.inputData > label {
  font-family: inherit;
  font-weight: 500;
  font-size: 13px;
}

/* ------------------------ */

.errorText {
  max-width: 329px;
  color: rgb(251, 133, 133);
}

.contentfile {
  position: relative;
  cursor: pointer;
}


.inputfile {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
}


.errorSelect {
  border: 2px rgb(255, 140, 140) solid !important;
  width: 355px;
  height: 34px;
  border-radius: 5px;
  color: rgba(143, 143, 143, 1);
  background-color: rgba(183, 177, 177, 0.5);
  font-family: inherit;
  font-size: 17;
  padding: 2px 10px;
}

.row{
  display: flex;
  flex-direction:row ;
  justify-content: space-around;
  margin-top: -20px;
  width: 23rem;
}


.imageUser{
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 1rem;
}


/* .formLeft {
  position: relative;
  width: 35%;
  height: 100%;
} */

/* .formLeft::before {
  content: "";
  position: absolute;

  width: 130px;
  height: 65px;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: #8f1414;

  z-index: 100;
} */

/* .formLeft::after {
  content: "";
  position: absolute;

  width: 130px;
  height: 130px;
  left: 50%;

  transform: translate(-50%, 0);
  background-color: #8f1414;
  border-radius: 360px;

  z-index: 100;
} */

/* .formLeft > img:first-child {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.8);

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
} */

/* .logoForm {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 65px;

  width: 70px;
  height: 70px;
  fill: #f5f5f5;
  color: #f5f5f5;
  z-index: 2000;
} */
