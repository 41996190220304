.container{
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  /* align-items: flex-end; */
  max-width: 100vw;
  /* align-items: center; */
  /* margin-right: -12rem; */
  padding-right: 7rem;
  padding-top: 4rem;
  margin-top: 1rem;
  margin-left: 15rem;
}

.filters{
  margin-top: 5.8rem ;
  margin-left: 3rem;
}