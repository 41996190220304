.cards {
    gap: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* width: fit-content; */
    padding: 1rem;
    background-color: white;
    box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.27);
    border-radius: .5rem;
}

.description {
    margin-top: 5px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    font-family: "Rubik", sans-serif;
    font-size: larger;
    padding-left: 6rem;
}
.ver{
    margin-right: -7rem;
}