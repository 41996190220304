.container {
  position: sticky;
  bottom: 8%;
  left: 75%;
  width: 300px;
  height: 40px;
  z-index: 100000;
}

.box {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  right: 0;
  width: 300px;
  height: 60px;

  box-shadow: 5px 5px 45px rgba(165, 165, 165, 0.22),
    -5px -5px 45px rgba(165, 165, 165, 0.22);

  border-radius: 5px;
  padding: 2px 5px;
  background-color: white;
  z-index: 100000;
}

.box > img {
  width: 25px;
  height: 25px;
  padding: 15px;
  border-left: 5px solid rgb(255, 0, 0);
}

.infoContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  flex-direction: column;
  height: 80%;
  width: 100%;
  margin-left: 5px;
}

.infoContainer > h3 {
  color: rgb(65, 65, 65);
  font-size: 15px;
  margin: 1px;
  font-family: "Rubik";
}

.infoContainer > p {
  color: grey;
  margin: 1px;
  font-size: 13px;
}

.close {
  color: grey;
  font-family: Helvetica;
  margin-right: 15px;
  font-size: 12px;
  font-weight: bolder;
  cursor: pointer;
}
