.container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 100vw;
    /* margin-right: -12rem; */
    padding-right: 20rem;
    padding-top: 4rem;
    /* border: 1px solid red; */
}

.subContainer{
    display: flex;
    flex-direction: column;
    width: fit-content;
    align-items: center;
    /* border: 1px solid green; */
}
